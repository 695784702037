import { BASE_FEATURES, PAGINATION_SIZE } from "../../constants";
import { PaginationData } from "../../interfaces";
import {
  ApiKey,
  CampaignDto,
  CampaignAnalytics,
  CustomerData,
  CustomerDto,
  CustomerEventDto,
  Feature,
  Integration,
  Link,
  Location,
  Program,
  ProgramAnalytics,
  ProgramAnalyticsAggregate,
  ProgramIntegration,
  SegmentDto,
  SubscriptionInfo,
  CouponDto,
  MinimalCoupon,
  CouponCounts,
} from "../../services/api";

export const PROGRAM_FEATURE_KEY = "program";

export interface Coupons {
  [couponId: string]: CouponDto;
}

export interface CouponStats {
  [couponId: string]: CouponCounts;
}

export interface Customers {
  [customerId: string]: CustomerDto;
}

export interface CustomersData {
  [customerId: string]: CustomerData;
}

export interface ProgramCampaigns {
  [campaignId: string]: CampaignDto;
}

export interface ProgramCampaignAnalytics {
  [campaignId: string]: CampaignAnalytics;
}

export interface ProgramLocations {
  [locationId: string]: Location;
}

export interface CampaignLinks {
  [linkId: string]: Link;
}

export interface ApiKeys {
  [apiKeyId: string]: ApiKey;
}

export interface ProgramAnalyticsAggregates {
  [type: string]: ProgramAnalyticsAggregate;
}

export interface ProgramFeatures {
  [featureId: string]: Feature;
}

export interface ProgramIntegrations {
  [programIntegrationId: string]: ProgramIntegration;
}

export interface CustomerEvents {
  [customerId: string]: CustomerEventDto[];
}

export interface ProgramState {
  loading: boolean;
  loadingFullScreen: boolean;
  loadingCreateSubscription: boolean;
  loadingKlaviyoAuth: boolean;
  loadingAnalytics: boolean;
  program: Program;
  integrations: Integration[];
  integrationsSearchTerm: string;
  integrationsFilters: string[];
  programIntegrations: ProgramIntegrations;
  analytics: ProgramAnalytics;
  analyticsAggregate: ProgramAnalyticsAggregates;
  customers: Customers;
  customersData: CustomersData;
  customersSearchTerm: string;
  customersPaginationData: PaginationData;
  customersEvents: CustomerEvents;
  customersEventsPaginationData: PaginationData;
  campaigns: ProgramCampaigns;
  campaignAnalytics: ProgramCampaignAnalytics;
  campaignSlug: string;
  locations: ProgramLocations;
  programLinks: Link[];
  campaignLinks: CampaignLinks;
  apiKeys: ApiKeys;
  features: ProgramFeatures;
  segments: SegmentDto[];
  subscriptionInfo: SubscriptionInfo;
  formError: string;
  coupons: Coupons;
  couponsSearchTerm: string;
  couponsPaginationData: PaginationData;
  couponsFilters: CouponDto.StatusEnum[];
  couponsSearchResults: MinimalCoupon[];
  couponStats: CouponStats;
}

export const initialState: ProgramState = {
  loading: false,
  loadingFullScreen: false,
  loadingCreateSubscription: false,
  loadingKlaviyoAuth: false,
  loadingAnalytics: false,
  program: null,
  integrations: [],
  integrationsSearchTerm: "",
  integrationsFilters: BASE_FEATURES,
  programIntegrations: {},
  analytics: {},
  analyticsAggregate: {},
  customers: {},
  customersData: {},
  customersSearchTerm: "",
  customersPaginationData: {
    limit: PAGINATION_SIZE,
    page: 1,
    total: 0,
  },
  customersEvents: {},
  customersEventsPaginationData: {
    limit: 5,
    page: 1,
    total: 0,
  },
  campaigns: {},
  campaignAnalytics: {},
  campaignSlug: null,
  locations: {},
  programLinks: [],
  campaignLinks: {},
  apiKeys: {},
  features: {},
  segments: [],
  subscriptionInfo: null,
  formError: "",
  coupons: {},
  couponsSearchTerm: "",
  couponsPaginationData: {
    limit: PAGINATION_SIZE,
    page: 1,
    total: 0,
  },
  couponsFilters: [CouponDto.StatusEnum.Available],
  couponsSearchResults: [],
  couponStats: {},
};

export const persistedKeys: (keyof ProgramState)[] = [
  "program",
  "integrations",
  "integrationsSearchTerm",
  "integrationsFilters",
  "programIntegrations",
  "analytics",
  "analyticsAggregate",
  "customers",
  "customersData",
  "customersSearchTerm",
  "campaigns",
  "campaignAnalytics",
  "locations",
  "programLinks",
  "campaignLinks",
  "apiKeys",
  "segments",
  "subscriptionInfo",
  "features",
  "coupons",
  "couponsSearchTerm",
  "couponsFilters",
];
