import { Environment } from "../app/interfaces";

export const environment: Environment = {
  production: true,
  name: "staging",
  host: "staging.admin.jericommerce.com",
  release: "0.0.47#588",
  dist: "588",
  apiUrl: "https://api-staging.jericommerce.com",
  webAppUrl: "https://staging.jeri.link",
  firebaseConfig: {
    apiKey: "AIzaSyCWeh4e6mYRs2V4js3pYU1uF7piIYbvk_g",
    authDomain: "jeripass-admin-app.firebaseapp.com",
    projectId: "jeripass-admin-app",
    storageBucket: "jeripass-admin-app.appspot.com",
    messagingSenderId: "958153644094",
    appId: "1:958153644094:web:431a4a1bfa170e837d196f",
  },
  shopifyClientId: "c644438bf56ca0dc3bac2c72f885aa6e",
  shopifyAppName: "jeripass-admin-staging",
};
