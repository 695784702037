/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, inject } from "@angular/core";
import { FilePondOptions } from "filepond";
import { ref, getDownloadURL, Storage, uploadBytesResumable } from "@angular/fire/storage";
import { Store } from "@ngrx/store";
import { RootState, appActions } from "../../store";
import { ToastService } from "../toast/toast.service";
import { TranslateService } from "@ngx-translate/core";
import { MenuItem } from "../../interfaces";
import { Feature, ProgramIntegration } from "../api";
import { FRONTEND_VARIABLES, MULTI_PASS } from "../../constants";
import { FormGroup } from "@angular/forms";

@Injectable({ providedIn: "root" })
export class UtilsService {
  private readonly storage: Storage = inject(Storage);

  constructor(
    private readonly store: Store<RootState>,
    private readonly toast: ToastService,
    private readonly translate: TranslateService,
  ) {}

  public getFilePondConfig(): FilePondOptions | any {
    return {
      imagePreviewMaxHeight: 40,
      maxFileSize: "1MB",
      acceptedFileTypes: ["image/png"],
      server: {
        process: async (fieldName, file, metadata, load, error, progress, abort): Promise<void> => {
          this.store.dispatch(appActions.setUploadImageLoading({ uploadImageLoading: true }));
          const storageRef = ref(this.storage, "files/" + `${new Date().getTime()}_${file.name}`);
          const uploadTask = uploadBytesResumable(storageRef, file);

          uploadTask.on(
            "state_changed",
            snapshot => {
              progress(true, snapshot.bytesTransferred, snapshot.totalBytes);
            },
            err => {
              this.store.dispatch(appActions.setUploadImageLoading({ uploadImageLoading: false }));
              error(err.message);
            },
            () => {
              this.store.dispatch(appActions.setUploadImageLoading({ uploadImageLoading: false }));
              getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
                load(downloadURL);
              });
            },
          );
        },
        load: async (source, load, error, progress, abort, headers): Promise<void> => {
          try {
            const request = new Request(source);
            const response = await fetch(request);
            const blob = await response.blob();
            load(blob);
          } catch (err) {
            error(err);
          }
        },
        revert: (uniqueFileId, load, error): void => {
          load();
        },
      },
    };
  }

  public copyToClipboard(
    value: string,
    props: { id?: string; title?: string; message?: string } =
    {
      id: "#toastContainer",
      title: this.translate.instant("TOAST.copyToClipboard.success.title"),
      message: this.translate.instant("TOAST.copyToClipboard.success.message"),
    }): void {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        this.toast.show({ title: props.title, message: props.message }, props.id);
      })
      .catch(() => {
        this.toast.show(
          {
            title: this.translate.instant("TOAST.copyToClipboard.error.title"),
            message: this.translate.instant("TOAST.copyToClipboard.error.message"),
          },
          props.id,
        );
      });
  }

  public formatDateToLocal(date: Date): string {
    const pad = (n: number): string | number => (n < 10 ? "0" + n : n);
    return (
      date.getFullYear() +
      "-" +
      pad(date.getMonth() + 1) +
      "-" +
      pad(date.getDate()) +
      "T" +
      pad(date.getHours()) +
      ":" +
      pad(date.getMinutes())
    );
  }

  public getProgramFrontendVariables(programIntegrations: ProgramIntegration[]): MenuItem[] {
    return FRONTEND_VARIABLES.reduce((variables, variable) => {
      if (!variable.integration) {
        return [...variables, variable];
      } else {
        programIntegrations?.forEach(programIntegration => {
          if (programIntegration["integration"].name === variable.integration) {
            if (variable.title === MULTI_PASS) {
              variables.push({
                ...variable,
                disabled: !programIntegration.properties["multiPassSecret"],
              });
            }
          }
        });
        return variables;
      }
    }, [] as MenuItem[]);
  }

  public isFeatureEnabled(programIntegration: ProgramIntegration, features: Feature[], featureName: string): boolean {
    return programIntegration?.enabledFeatures
      ?.map(enabledFeatureId => features.find(feature => feature.id === enabledFeatureId)?.name)
      .some(name => name === featureName);
  }

  public validateForm(form: FormGroup): void {
    form.markAsDirty();
    form.markAllAsTouched();

    Object.keys(form.controls).forEach(key => {
      const control = form.get(key);
      control.setValue(control.value);
      control.markAsDirty();
      control.markAsTouched();
    });

    form.updateValueAndValidity();
  }

  public getRandomString(length: number): string {
    const possible = "ABCDEF1234567890";
    let text = "";

    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
  }
}
