/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action, Store } from "@ngrx/store";
import {
  Observable,
  catchError,
  combineLatest,
  filter,
  finalize,
  forkJoin,
  interval,
  map,
  mergeMap,
  of,
  switchMap,
  take,
  takeWhile,
  tap,
  withLatestFrom,
} from "rxjs";
import { RootState, appActions, fromApp, fromProgram, fromRouter, programActions } from "..";
import {
  AdminService,
  AnalyticsService,
  CampaignDto,
  CouponService,
  Feature,
  IntegrationService,
  Program,
  ProgramIntegration,
  ProgramService,
  ShopifyControllerTokenExchangeRequestParams,
  SubscriptionInfo,
} from "../../services/api";
import { HttpClient, HttpErrorResponse, HttpResponse, HttpStatusCode } from "@angular/common/http";
import { Router } from "@angular/router";
import { AppRouteParams, AppRoutes, Platforms } from "../../enums";
import { ToastService } from "../../services/toast/toast.service";
import { TranslateService } from "@ngx-translate/core";
import { MatDialog } from "@angular/material/dialog";
import { GenerateApiKeyComponent } from "../../modals/generate-api-key/generate-api-key.component";
import { GenerateApiKeyData } from "../../modals/generate-api-key/generate-api-key.interface";
import { ShopifyAppBridgeService } from "../../services/shopify-app-bridge/shopify-app-bridge.service";
import { UnderstoodComponent } from "../../modals/understood/understood.component";
import { UpdateIntegrationConfirmComponent } from "../../modals/update-integration-confirm/update-integration-confirm.component";
import {
  UpdateIntegrationConfirmData,
  UpdateIntegrationConfirmResult,
} from "../../modals/update-integration-confirm/update-integration-confirm.interface";
import { UnderstoodData } from "../../modals/understood/understood.interface";
import { concatLatestFrom } from "@ngrx/operators";
import { removeUnclaimedSuccess } from "./actions";

@Injectable()
export class ProgramEffects {
  public getProgram$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(programActions.getProgram),
      mergeMap(({ slug, programId, loadingFullScreen }) =>
        this.programService.programControllerGetProgram({ programId: programId || slug }).pipe(
          map(program => programActions.getProgramSuccess({ program, loadingFullScreen })),
          catchError((error: HttpErrorResponse) => of(programActions.getProgramFailure({ error, loadingFullScreen }))),
        ),
      ),
    ),
  );

  public getProgramSuccess$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(programActions.getProgramSuccess),
        withLatestFrom(this.store.select(fromApp.selectIframeShop)),
        filter(([, shop]) => !!shop),
        tap(async ([{ program }, shop]) => {
          const sessionToken = await this.shopifyAppBridgeService.getSessionToken();
          this.store.dispatch(programActions.tokenExchange({ programId: program.id, tokenExchange: { shop, sessionToken } }));
        }),
      ),
    { dispatch: false },
  );

  public getProgramFailure$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(programActions.getProgramFailure),
        filter(({ error }) => error["statusCode"] === HttpStatusCode.NotFound),
        tap(() => this.router.navigate([AppRoutes.onboarding])),
      ),
    { dispatch: false },
  );

  public updateProgram$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(programActions.updateProgram),
      mergeMap(({ params, toastId }) =>
        this.adminService.adminControllerUpdateProgram(params).pipe(
          map(program => programActions.updateProgramSuccess({ program, toastId })),
          catchError(() => of(programActions.updateProgramFailure())),
        ),
      ),
    ),
  );

  public updateProgramSuccess$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(programActions.updateProgramSuccess),
        filter(({ toastId }) => !!toastId),
        tap(({ toastId }) => {
          this.toast.show(
            {
              title: this.translate.instant("TOAST.save.title"),
              message: this.translate.instant("TOAST.save.message"),
            },
            toastId,
          );
        }),
      ),
    { dispatch: false },
  );

  public persistProgram$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(programActions.persistProgram),
      withLatestFrom(this.store.select(fromProgram.selectProgram), this.store.select(fromProgram.selectProgramLinks)),
      mergeMap(([, program, links]) =>
        forkJoin([
          this.adminService.adminControllerUpdateProgram({ programId: program.id, updateProgram: program }),
          this.adminService.adminControllerPatchProgramLinks({ programId: program.id, link: links }),
        ]).pipe(
          mergeMap(([program, programLinks]) => [
            programActions.persistProgramSuccess({ program }),
            programActions.persistProgramLinksSuccess({ programLinks }),
          ]),
        ),
      ),
      catchError((error: HttpErrorResponse) => of(programActions.persistProgramFailure({ reason: error.error?.response?.message }))),
    ),
  );

  public persistProgramSuccess$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(programActions.persistProgramSuccess),
        tap(() => {
          this.store.dispatch(appActions.setDirtyForm({ dirtyForm: false }));
          this.toast.show({
            title: this.translate.instant("TOAST.save.title"),
            message: this.translate.instant("TOAST.save.message"),
          });
        }),
      ),
    { dispatch: false },
  );

  public getProgramLinks$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(programActions.getProgramLinks),
      mergeMap(params =>
        this.adminService.adminControllerGetProgramLinks({ visibility: "0", programId: params.programId }).pipe(
          map(programLinks => programActions.getProgramLinksSuccess({ programLinks })),
          catchError((error: HttpErrorResponse) => of(programActions.getProgramLinksFailure({ reason: error.error?.response?.message }))),
        ),
      ),
    ),
  );

  public getIntegrations$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(programActions.getIntegrations),
      mergeMap(() =>
        this.adminService.adminControllerGetIntegrations().pipe(
          map(integrations => programActions.getIntegrationsSuccess({ integrations })),
          catchError(() => of(programActions.getIntegrationsFailure())),
        ),
      ),
    ),
  );

  public getProgramIntegrations$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(programActions.getProgramIntegrations),
      mergeMap(params =>
        this.programService.programControllerGetProgramIntegrations(params).pipe(
          map(programIntegrations => programActions.getProgramIntegrationsSuccess({ programIntegrations })),
          catchError(() => of(programActions.getProgramIntegrationsFailure())),
        ),
      ),
    ),
  );

  public createProgram$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(programActions.createProgram),
      mergeMap(({ params, callback }) =>
        this.adminService.adminControllerCreateProgram(params).pipe(
          mergeMap(program => of(programActions.createProgramSuccess({ program, callback }))),
          catchError((error: HttpErrorResponse) => of(programActions.createProgramFailure({ reason: error.error?.response?.message }))),
        ),
      ),
    ),
  );

  public getAnalytics$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(programActions.getAnalytics),
      mergeMap(({ params }) =>
        this.analyticsService.analyticsControllerGetAnalytics(params).pipe(
          map(analytics => programActions.getAnalyticsSuccess({ analytics })),
          catchError(() => of(programActions.getAnalyticsFailure())),
        ),
      ),
    ),
  );

  public getAnalyticsAggregate$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(programActions.getAnalyticsAggregate),
      mergeMap(({ params }) =>
        this.analyticsService.analyticsControllerGetAnalyticsAggregate(params).pipe(
          map(analyticsAggregate =>
            programActions.getAnalyticsAggregateSuccess({
              analyticsAggregate,
              analyticsAggregateType: params.information,
            }),
          ),
          catchError(() => of(programActions.getAnalyticsAggregateFailure())),
        ),
      ),
    ),
  );

  public getProgramCustomers$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(programActions.getProgramCustomers),
      withLatestFrom(this.store.select(fromProgram.selectCustomersPaginationData)),
      mergeMap(([params, { limit }]) => {
        if (limit !== params.limit) {
          params = {
            ...params,
            page: 1,
          };
        }
        return this.adminService.adminControllerGetProgramCustomers(params).pipe(
          map(customers => programActions.getProgramCustomersSuccess({ limit: params.limit, customers })),
          catchError(() => of(programActions.getProgramCustomersFailure())),
        );
      }),
    ),
  );

  public getCustomerById$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(programActions.getCustomerById),
      mergeMap(params =>
        this.adminService.adminControllerGetCustomerById(params).pipe(
          map(customer => programActions.getCustomerByIdSuccess({ customer })),
          catchError(() => of(programActions.getCustomerByIdFailure())),
        ),
      ),
    ),
  );

  public getProgramCampaigns$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(programActions.getProgramCampaigns),
      mergeMap(({ params }) =>
        this.adminService.adminControllerGetProgramCampaigns(params).pipe(
          map(campaigns => programActions.getProgramCampaignsSuccess({ campaigns })),
          catchError(() => of(programActions.getProgramCampaignsFailure())),
        ),
      ),
    ),
  );

  public getProgramLocations$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(programActions.getProgramLocations),
      mergeMap(params =>
        this.adminService.adminControllerGetProgramLocations(params).pipe(
          map(locations => programActions.getProgramLocationsSuccess({ locations })),
          catchError(() => of(programActions.getProgramLocationsFailure())),
        ),
      ),
    ),
  );

  public addLocation$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(programActions.addLocation),
      mergeMap(params =>
        this.adminService.adminControllerAddLocation(params).pipe(
          map(location => programActions.addLocationSuccess({ location })),
          catchError((error: HttpErrorResponse) => of(programActions.addLocationFailure({ formError: error.error?.response?.message }))),
        ),
      ),
    ),
  );

  public addLocationSuccess$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(programActions.addLocationSuccess),
        withLatestFrom(this.store.select(fromRouter.selectRouteParam(AppRouteParams.programSlug))),
        tap(([{ location }, programSlug]) => {
          const duration = 1000;

          this.toast.show({
            title: this.translate.instant("TOAST.save.title"),
            message: this.translate.instant("TOAST.save.message"),
            duration,
          });

          setTimeout(() => {
            this.router.navigate([programSlug, AppRoutes.campaigns, AppRoutes.locationBased, AppRoutes.locations, location.id]);
          }, duration);
        }),
      ),
    { dispatch: false },
  );

  public updateLocation$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(programActions.updateLocation),
      mergeMap(params =>
        this.adminService.adminControllerUpdateLocation(params).pipe(
          map(location => programActions.updateLocationSuccess({ location })),
          catchError(error => of(programActions.updateLocationFailure({ formError: error?.response?.message }))),
        ),
      ),
    ),
  );

  public deleteLocation$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(programActions.deleteLocation),
      mergeMap(({ params, callback }) =>
        this.adminService.adminControllerDeleteLocation(params).pipe(
          map(() => programActions.deleteLocationSuccess({ locationId: params.locationId, callback })),
          catchError(error => of(programActions.deleteLocationFailure({ formError: error?.response?.message }))),
        ),
      ),
    ),
  );

  public createCampaign$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(programActions.createCampaign),
      mergeMap(({ params, links }) =>
        (links?.length
          ? forkJoin([
            ...links?.map(link => {
              if (link.id) {
                return this.adminService.adminControllerUpdateLink({
                  linkId: link.id,
                  programId: params.programId,
                  updateLink: {
                    alias: link.alias,
                    label: link.label,
                    url: link.url,
                    programId: params.programId,
                    visibility: "1",
                  },
                });
              } else {
                return this.adminService.adminControllerAddLink({
                  programId: params.programId,
                  createLink: {
                    alias: link.alias,
                    label: link.label,
                    url: link.url,
                    programId: params.programId,
                    visibility: "1",
                  },
                });
              }
            }),
          ])
          : of([])
        ).pipe(
          mergeMap(links => {
            links.forEach(link => {
              this.store.dispatch(programActions.updateCampaignLinkSuccess({ campaignLink: link }));
            });

            return this.adminService
              .adminControllerCreateCampaign({
                ...params,
                createCampaign: {
                  ...params.createCampaign,
                  properties: {
                    ...params.createCampaign.properties,
                    audience: params.createCampaign.properties.audience
                      ? {
                        platforms: params.createCampaign.properties.audience?.platforms
                          ? params.createCampaign.properties.audience?.platforms?.length > 1
                            ? [Platforms.all]
                            : params.createCampaign.properties.audience.platforms
                          : undefined,
                        segments: params.createCampaign.properties.audience?.segments
                          ? params.createCampaign.properties.audience?.segments
                          : undefined,
                      }
                      : undefined,
                    links: links?.length ? links?.map(link => link.id) : undefined,
                  },
                },
              })
              .pipe(
                map(campaign => programActions.createCampaignSuccess({ campaign })),
                catchError(error => of(programActions.createCampaignFailure({ formError: error?.response?.message }))),
              );
          }),
          catchError(error => of(programActions.createCampaignFailure({ formError: error?.response?.message }))),
        ),
      ),
    ),
  );

  public createCampaignSuccess$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(programActions.createCampaignSuccess),
        withLatestFrom(this.store.select(fromRouter.selectRouteParam(AppRouteParams.programSlug))),
        tap(([{ campaign }, programSlug]) => {
          const duration = 1000;

          this.toast.show({
            title: this.translate.instant("TOAST.save.title"),
            message: this.translate.instant("TOAST.save.message"),
            duration,
          });

          setTimeout(() => {
            switch (campaign.type) {
              case CampaignDto.TypeEnum._0:
                this.router.navigate([programSlug, AppRoutes.campaigns, AppRoutes.locationBased, campaign.id]);
                break;
              case CampaignDto.TypeEnum._1:
                this.router.navigate([programSlug, AppRoutes.campaigns, AppRoutes.scheduled, campaign.id]);
                break;
              case CampaignDto.TypeEnum._2:
                this.router.navigate([programSlug, AppRoutes.campaigns, AppRoutes.links, campaign.id]);
                break;
              case CampaignDto.TypeEnum._3:
                this.router.navigate([programSlug, AppRoutes.campaigns, AppRoutes.triggeredBased, campaign.id]);
                break;
              case CampaignDto.TypeEnum._4:
                this.router.navigate([programSlug, AppRoutes.campaigns, AppRoutes.anonymous, campaign.id]);
                break;
            }
          }, duration);
        }),
      ),
    { dispatch: false },
  );

  public updateCampaign$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(programActions.updateCampaign),
      mergeMap(({ params, links }) =>
        (links?.length
          ? forkJoin([
            ...links?.map(link => {
              if (link.id) {
                return this.adminService.adminControllerUpdateLink({
                  linkId: link.id,
                  programId: params.programId,
                  updateLink: {
                    alias: link.alias,
                    label: link.label,
                    url: link.url,
                    programId: params.programId,
                    visibility: "1",
                  },
                });
              } else {
                return this.adminService.adminControllerAddLink({
                  programId: params.programId,
                  createLink: {
                    alias: link.alias,
                    label: link.label,
                    url: link.url,
                    programId: params.programId,
                    visibility: "1",
                  },
                });
              }
            }),
          ])
          : of([])
        ).pipe(
          mergeMap(links => {
            links.forEach(link => {
              this.store.dispatch(programActions.updateCampaignLinkSuccess({ campaignLink: link }));
            });

            return this.adminService
              .adminControllerUpdateCampaign({
                ...params,
                updateCampaign: {
                  ...params.updateCampaign,
                  properties: {
                    ...params.updateCampaign.properties,
                    audience: params.updateCampaign.properties.audience
                      ? {
                        platforms: params.updateCampaign.properties.audience?.platforms
                          ? params.updateCampaign.properties.audience?.platforms?.length > 1
                            ? [Platforms.all]
                            : params.updateCampaign.properties.audience.platforms
                          : undefined,
                        segments: params.updateCampaign.properties.audience?.segments
                          ? params.updateCampaign.properties.audience?.segments
                          : undefined,
                      }
                      : undefined,
                    links: links?.length ? links?.map(link => link.id) : undefined,
                  },
                },
              })
              .pipe(
                map(campaign => programActions.updateCampaignSuccess({ campaign })),
                catchError(error => of(programActions.updateCampaignFailure({ formError: error?.response?.message }))),
              );
          }),
          catchError(error => of(programActions.updateCampaignFailure({ formError: error?.response?.message }))),
        ),
      ),
    ),
  );

  public reactivateCampaign$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(programActions.reactivateCampaign),
      mergeMap(({ programId, campaignId }) =>
        this.adminService.adminControllerReactivateCampaign({ programId, campaignId }).pipe(
          map(campaign => programActions.updateCampaignSuccess({ campaign })),
          catchError(error => of(programActions.updateCampaignFailure({ formError: error?.response?.message }))),
        ),
      ),
    ),
  );

  public deleteCampaign$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(programActions.deleteCampaign),
      mergeMap(({ params, callback }) =>
        this.adminService.adminControllerDeleteCampaign(params).pipe(
          map(() => programActions.deleteCampaignSuccess({ campaignId: params.campaignId, callback })),
          catchError(error => of(programActions.deleteCampaignFailure({ formError: error?.response?.message }))),
        ),
      ),
    ),
  );

  public getCampaignLinks$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(programActions.getCampaignLinks),
      mergeMap(params =>
        this.adminService.adminControllerGetProgramLinks({ visibility: "1", programId: params.programId }).pipe(
          map(campaignLinks => programActions.getCampaignLinksSuccess({ campaignLinks })),
          catchError(() => of(programActions.getCampaignLinksFailure())),
        ),
      ),
    ),
  );

  public success$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(programActions.updateLocationSuccess, programActions.updateCampaignSuccess, programActions.updateCouponSuccess),
        tap(() => {
          this.toast.show({
            title: this.translate.instant("TOAST.save.title"),
            message: this.translate.instant("TOAST.save.message"),
          });
        }),
      ),
    { dispatch: false },
  );

  public executeCallBack$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          programActions.createProgramSuccess,
          programActions.deleteCampaignSuccess,
          programActions.deleteLocationSuccess,
          programActions.sendPushSuccess,
          programActions.addProgramIntegrationSuccess,
          programActions.updateProgramIntegrationSuccess,
          programActions.addPointsToCustomerSuccess,
          programActions.removePointsToCustomerSuccess,
          programActions.generateCouponCodeBatchSuccess,
        ),
        filter(({ callback }) => !!callback),
        tap(({ callback }) => callback()),
      ),
    { dispatch: false },
  );

  public getProgramApiKeys$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(programActions.getProgramApiKeys),
      mergeMap(params =>
        this.adminService.adminControllerGetProgramApiKeys(params).pipe(
          map(apiKeys => programActions.getProgramApiKeysSuccess({ apiKeys })),
          catchError(() => of(programActions.getProgramApiKeysFailure())),
        ),
      ),
    ),
  );

  public generateApiKey$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(programActions.generateApiKey),
      mergeMap(params =>
        this.adminService.adminControllerGenerateApiKey(params).pipe(
          map(apiKey => programActions.generateApiKeySuccess({ apiKey })),
          catchError(() => of(programActions.generateApiKeyFailure())),
        ),
      ),
    ),
  );

  public generateApiKeySuccess$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(programActions.generateApiKeySuccess),
        tap(({ apiKey }) => {
          this.dialog.open<GenerateApiKeyComponent, GenerateApiKeyData, void>(GenerateApiKeyComponent, {
            width: "600px",
            data: {
              value: apiKey.value,
            },
          });
        }),
      ),
    { dispatch: false },
  );

  public deleteApiKey$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(programActions.deleteApiKey),
      mergeMap(params =>
        this.adminService.adminControllerDeleteApiKey(params).pipe(
          map(() => programActions.deleteApiKeySuccess({ apiKeyId: params.apiKeyId })),
          catchError(() => of(programActions.deleteApiKeyFailure())),
        ),
      ),
    ),
  );

  public addProgramIntegration$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(programActions.addProgramIntegration),
      withLatestFrom(this.store.select(fromProgram.selectProgramIntegrations), this.store.select(fromProgram.selectFeatures)),
      mergeMap(([{ programId, createIntegration, integrationName, callback }, stateProgramIntegrations, stateFeatures]) => {
        const programIntegrationFeaturesEnabled: Feature[] = stateFeatures.filter(feature =>
          createIntegration.enabledFeatures.includes(feature.id),
        );

        const affectedIntegrations: ProgramIntegration[] = programIntegrationFeaturesEnabled
          .map(programIntegrationFeature =>
            !programIntegrationFeature.multi
              ? stateProgramIntegrations.find(programIntegration =>
                programIntegration.enabledFeatures.includes(programIntegrationFeature.id),
              )
              : null,
          )
          .filter(Boolean);

        if (affectedIntegrations.length) {
          const affectedFeatures: string = programIntegrationFeaturesEnabled
            .map(programIntegrationFeature => {
              if (!programIntegrationFeature.multi) {
                const affectedIntegration = stateProgramIntegrations.find(programIntegration =>
                  programIntegration.enabledFeatures.includes(programIntegrationFeature.id),
                );

                if (affectedIntegration) {
                  return this.translate.instant(`FEATURES.${programIntegrationFeature.name}`);
                }
              }

              return null;
            })
            .filter(Boolean)
            .join(", ");

          const dialogRef = this.dialog.open<
          UpdateIntegrationConfirmComponent,
          UpdateIntegrationConfirmData,
          UpdateIntegrationConfirmResult
          >(UpdateIntegrationConfirmComponent, {
            width: "600px",
            data: {
              description: this.translate.instant("UPDATE_INTEGRATION_CONFIRM.description", { affectedFeatures }),
            },
          });

          return dialogRef.afterClosed().pipe(
            mergeMap(({ confirm }: UpdateIntegrationConfirmResult) => {
              if (confirm) {
                return forkJoin([
                  ...affectedIntegrations.map(affectedIntegration =>
                    this.adminService.adminControllerUpdateProgramIntegration({
                      programId,
                      programIntegrationId: affectedIntegration.id,
                      updateIntegration: {
                        programId,
                        integrationId: affectedIntegration.integrationId,
                        properties: affectedIntegration.properties,
                        enabledFeatures: affectedIntegration.enabledFeatures.filter(enabledFeature => {
                          const feature = stateFeatures.find(feature => feature.id === enabledFeature);
                          return !createIntegration.enabledFeatures.includes(enabledFeature) || feature.multi;
                        }),
                      },
                    }),
                  ),
                ]).pipe(
                  mergeMap(programIntegrations => {
                    const updateSuccessActions = programIntegrations.map(programIntegration => {
                      const integrationName = stateProgramIntegrations.find(pIntegration => pIntegration.id === programIntegration.id)
                        ?.integration?.name;
                      return programActions.updateProgramIntegrationSuccess({
                        integrationName,
                        programIntegration,
                        callback: () => {},
                      });
                    });

                    return this.adminService.adminControllerAddIntegration({ programId, createIntegration }).pipe(
                      mergeMap(programIntegration => [
                        ...updateSuccessActions,
                        programActions.addProgramIntegrationSuccess({ programIntegration, integrationName, callback }),
                      ]),
                      catchError(error => {
                        const formError = error?.message || error.error?.response?.message;
                        return of(programActions.addProgramIntegrationFailure({ formError }));
                      }),
                    );
                  }),
                  catchError(error => {
                    const formError = error?.message || error.error?.response?.message;
                    return of(programActions.addProgramIntegrationFailure({ formError }));
                  }),
                );
              } else {
                return of(programActions.addProgramIntegrationFailure({ formError: "" }));
              }
            }),
          );
        } else {
          return this.adminService.adminControllerAddIntegration({ programId, createIntegration }).pipe(
            map(programIntegration => programActions.addProgramIntegrationSuccess({ programIntegration, integrationName, callback })),
            catchError(error => {
              const formError = error?.message || error.error?.response?.message;
              return of(programActions.addProgramIntegrationFailure({ formError }));
            }),
          );
        }
      }),
    ),
  );

  public updateProgramIntegration$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(programActions.updateProgramIntegration),
      withLatestFrom(this.store.select(fromProgram.selectProgramIntegrations), this.store.select(fromProgram.selectFeatures)),
      mergeMap(
        ([{ programId, programIntegrationId, updateIntegration, integrationName, callback }, stateProgramIntegrations, stateFeatures]) => {
          const programIntegration = stateProgramIntegrations.find(programIntegration => programIntegration.id === programIntegrationId);

          const programIntegrationFeaturesDisabled: Feature[] = programIntegration.enabledFeatures
            .map(enabledFeatureId =>
              !updateIntegration.enabledFeatures.includes(enabledFeatureId)
                ? stateFeatures.find(feature => feature.id === enabledFeatureId)
                : null,
            )
            .filter(Boolean);

          const multiFalseIntegrations = programIntegrationFeaturesDisabled.filter(
            programIntegrationFeatureDisabled => !programIntegrationFeatureDisabled.multi,
          );

          if (multiFalseIntegrations?.length) {
            const dialogRef = this.dialog.open<UnderstoodComponent, UnderstoodData, void>(UnderstoodComponent, {
              width: "600px",
              data: {
                title: "UNDERSTOOD_PAGE.unableToSaveTitle",
                description: this.translate.instant("UNDERSTOOD_PAGE.unableToSaveDescription", {
                  featureName: this.translate.instant(`FEATURES.${multiFalseIntegrations[0]?.name}`),
                }),
                buttonColor: "transparent",
              },
            });

            return dialogRef.afterClosed().pipe(map(() => programActions.updateProgramIntegrationFailure({ formError: "" })));
          }

          const programIntegrationFeaturesEnabled: Feature[] = stateFeatures.filter(feature =>
            updateIntegration.enabledFeatures.includes(feature.id),
          );

          const affectedIntegrations: ProgramIntegration[] = programIntegrationFeaturesEnabled
            .map(programIntegrationFeature => {
              if (!programIntegrationFeature.multi) {
                const programIntegration = stateProgramIntegrations.find(programIntegration =>
                  programIntegration.enabledFeatures.includes(programIntegrationFeature.id),
                );

                if (programIntegration?.id !== programIntegrationId) return programIntegration;
              }
              return null;
            })
            .filter(Boolean);

          if (affectedIntegrations.length) {
            const affectedFeatures: string = programIntegrationFeaturesEnabled
              .map(programIntegrationFeature => {
                if (!programIntegrationFeature.multi) {
                  const affectedIntegration = stateProgramIntegrations.find(programIntegration =>
                    programIntegration.enabledFeatures.includes(programIntegrationFeature.id),
                  );

                  if (affectedIntegration) {
                    return this.translate.instant(`FEATURES.${programIntegrationFeature.name}`);
                  }
                }

                return null;
              })
              .filter(Boolean)
              .join(", ");

            const dialogRef = this.dialog.open<
            UpdateIntegrationConfirmComponent,
            UpdateIntegrationConfirmData,
            UpdateIntegrationConfirmResult
            >(UpdateIntegrationConfirmComponent, {
              width: "600px",
              data: {
                description: this.translate.instant("UPDATE_INTEGRATION_CONFIRM.description", { affectedFeatures }),
              },
            });

            return dialogRef.afterClosed().pipe(
              mergeMap(({ confirm }: UpdateIntegrationConfirmResult) => {
                if (confirm) {
                  return forkJoin([
                    ...affectedIntegrations.map(affectedIntegration =>
                      this.adminService.adminControllerUpdateProgramIntegration({
                        programId,
                        programIntegrationId: affectedIntegration.id,
                        updateIntegration: {
                          programId,
                          integrationId: affectedIntegration.integrationId,
                          properties: affectedIntegration.properties,
                          enabledFeatures: affectedIntegration.enabledFeatures.filter(enabledFeature => {
                            const feature = stateFeatures.find(feature => feature.id === enabledFeature);
                            return !updateIntegration.enabledFeatures.includes(enabledFeature) || feature.multi;
                          }),
                        },
                      }),
                    ),
                  ]).pipe(
                    mergeMap(programIntegrations => {
                      const updateSuccessActions = programIntegrations.map(programIntegration => {
                        const integrationName = stateProgramIntegrations.find(pIntegration => pIntegration.id === programIntegration.id)
                          ?.integration?.name;
                        return programActions.updateProgramIntegrationSuccess({
                          integrationName,
                          programIntegration,
                          callback: () => {},
                        });
                      });

                      return this.adminService
                        .adminControllerUpdateProgramIntegration({ programId, programIntegrationId, updateIntegration })
                        .pipe(
                          mergeMap(programIntegration => [
                            ...updateSuccessActions,
                            programActions.updateProgramIntegrationSuccess({ programIntegration, integrationName, callback }),
                          ]),
                          catchError(error => {
                            const formError = error?.message || error.error?.response?.message;
                            return of(programActions.updateProgramIntegrationFailure({ formError }));
                          }),
                        );
                    }),
                    catchError(error => {
                      const formError = error?.message || error.error?.response?.message;
                      return of(programActions.updateProgramIntegrationFailure({ formError }));
                    }),
                  );
                } else {
                  return of(programActions.updateProgramIntegrationFailure({ formError: "" }));
                }
              }),
            );
          } else {
            return this.adminService.adminControllerUpdateProgramIntegration({ programId, programIntegrationId, updateIntegration }).pipe(
              map(programIntegration => programActions.updateProgramIntegrationSuccess({ programIntegration, integrationName, callback })),
              catchError(error => {
                const formError = error?.message || error.error?.response?.message;
                return of(programActions.updateProgramIntegrationFailure({ formError }));
              }),
            );
          }
        },
      ),
    ),
  );

  public tokenExchange$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(programActions.tokenExchange),
      mergeMap(params =>
        this.shopifyControllerTokenExchange(params).pipe(
          map(() => programActions.tokenExchangeSuccess()),
          catchError(() => of(programActions.tokenExchangeFailure())),
        ),
      ),
    ),
  );

  public sendPush$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(programActions.sendPush),
      mergeMap(({ programId, apiKey, sendPush, campaignId, callback }) =>
        this.programService.programControllerSendPush({ programId, apiKey, sendPush, campaignId }).pipe(
          map(() => programActions.sendPushSuccess({ callback })),
          catchError(error => {
            const formError = error?.message || error.error?.response?.message;
            return of(programActions.sendPushFailure({ formError }));
          }),
        ),
      ),
    ),
  );

  public klaviyoAuthorize$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(programActions.klaviyoAuthorize),
      mergeMap(params =>
        this.integrationService.klaviyoControllerAuthorize(params).pipe(
          map(({ redirectUri }) => programActions.klaviyoAuthorizeSuccess({ redirectUri })),
          catchError(() => of(programActions.klaviyoAuthorizeFailure())),
        ),
      ),
    ),
  );

  public klaviyoAuthorizeSuccess$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(programActions.klaviyoAuthorizeSuccess),
        tap(({ redirectUri }) => window.open(redirectUri, "_blank")),
        withLatestFrom(this.store.select(fromProgram.selectProgram)),
        filter(([, program]) => !!program),
        switchMap(([, { id }]) =>
          interval(3000).pipe(
            tap(() => this.store.dispatch(programActions.getProgramIntegrations({ programId: id, isLongPolling: true }))),
            switchMap(() =>
              combineLatest([
                this.store.select(fromProgram.selectProgram),
                this.store.select(fromProgram.selectProgramIntegrationByName(Program.IntegrationEnum.Klaviyo)),
              ]),
            ),
            takeWhile(([_, programIntegration]) => !programIntegration?.properties["accessToken"]),
            take(20),
            finalize(() => {
              this.store.dispatch(programActions.setLoadingKlaviyoAuth({ loadingKlaviyoAuth: false }));
            }),
          ),
        ),
      ),
    { dispatch: false },
  );

  public klaviyoCallback$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(programActions.klaviyoCallback),
      mergeMap(params =>
        this.integrationService.klaviyoControllerCallback(params).pipe(
          map(() => programActions.klaviyoCallbackSuccess({ programId: params.programId })),
          catchError(() => of(programActions.klaviyoCallbackFailure())),
        ),
      ),
    ),
  );

  public klaviyoRevoke$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(programActions.klaviyoRevoke),
      mergeMap(({ programId }) =>
        this.integrationService.klaviyoControllerRevoke({ programId }).pipe(
          map(() => programActions.klaviyoRevokeSuccess({ programId })),
          catchError((error: HttpErrorResponse) => of(programActions.klaviyoRevokeFailure({ reason: error.error.message }))),
        ),
      ),
    ),
  );

  public getFeatures$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(programActions.getFeatures),
      mergeMap(() =>
        this.adminService.adminControllerGetFeatures().pipe(
          map(features => programActions.getFeaturesSuccess({ features })),
          catchError(() => of(programActions.getFeaturesFailure())),
        ),
      ),
    ),
  );

  public getSegments$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(programActions.getSegments),
      mergeMap(params =>
        this.adminService.adminControllerGetSegments(params).pipe(
          map(segments => programActions.getSegmentsSuccess({ segments })),
          catchError(() => of(programActions.getSegmentsFailure())),
        ),
      ),
    ),
  );

  public addPointsToCustomer$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(programActions.addPointsToCustomer),
      mergeMap(({ programId, customerId, createBooking, callback }) =>
        this.adminService.adminControllerAddPointsToCustomer({ programId, customerId, createBooking }).pipe(
          map(customer => programActions.addPointsToCustomerSuccess({ customer, callback })),
          catchError(error => {
            const formError = error?.message || error.error?.response?.message;
            return of(programActions.addPointsToCustomerFailure({ formError }));
          }),
        ),
      ),
    ),
  );

  public removePointsToCustomer$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(programActions.removePointsToCustomer),
      mergeMap(({ programId, customerId, createBooking, callback }) =>
        this.adminService.adminControllerRemovePointsToCustomer({ programId, customerId, createBooking }).pipe(
          map(customer => programActions.removePointsToCustomerSuccess({ customer, callback })),
          catchError(error => {
            const formError = error?.message || error.error?.response?.message;
            return of(programActions.removePointsToCustomerFailure({ formError }));
          }),
        ),
      ),
    ),
  );

  public getCustomerEvents$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(programActions.getCustomerEvents),
      mergeMap(params =>
        this.adminService.adminControllerGetCustomerEvents(params).pipe(
          map(paginatedCustomerEvents =>
            programActions.getCustomerEventsSuccess({ customerId: params.customerId, paginatedCustomerEvents }),
          ),
          catchError(() => of(programActions.getCustomerEventsFailure())),
        ),
      ),
    ),
  );

  public getProgramSubscriptionInfo$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(programActions.getProgramSubscriptionInfo),
      mergeMap(({ params, redirect }) =>
        this.adminService.adminControllerGetProgramSubscriptionInfo(params).pipe(
          map(subscriptionInfo => programActions.getProgramSubscriptionInfoSuccess({ subscriptionInfo, redirect })),
          catchError(() => of(programActions.getProgramSubscriptionInfoFailure())),
        ),
      ),
    ),
  );

  public createProgramSubscription$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(programActions.createProgramSubscription),
      mergeMap(({ params, navigate }) =>
        this.adminService.adminControllerCreateProgramSubscription(params).pipe(
          map(redirectLink => programActions.createProgramSubscriptionSuccess({ redirectLink, navigate })),
          catchError(() => of(programActions.createProgramSubscriptionFailure())),
        ),
      ),
    ),
  );

  public createProgramSubscriptionSuccess$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(programActions.createProgramSubscriptionSuccess),
        tap(({ redirectLink }) => {
          window.open(redirectLink?.redirectLink, "_blank");
        }),
        withLatestFrom(this.store.select(fromProgram.selectProgram)),
        filter(([, program]) => !!program),
        switchMap(([{ navigate }, { id, slug }]) =>
          interval(3000).pipe(
            tap(() => {
              this.store.dispatch(programActions.getProgramSubscriptionInfo({ params: { programId: id } }));
            }),
            switchMap(() =>
              combineLatest([this.store.select(fromProgram.selectProgram), this.store.select(fromProgram.selectSubscriptionInfo)]),
            ),
            takeWhile(
              ([_, subscriptionInfo]) =>
                subscriptionInfo?.status === null ||
                subscriptionInfo.status === SubscriptionInfo.StatusEnum[1] ||
                subscriptionInfo.status === SubscriptionInfo.StatusEnum._0,
            ),
            take(20),
            finalize(() => {
              this.store.dispatch(programActions.setLoadingCreateProgramSubscription({ loadingCreateSubscription: false }));
              if (navigate) this.router.navigate([slug, AppRoutes.design]);
            }),
          ),
        ),
      ),
    { dispatch: false },
  );

  public getCampaignById$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(programActions.getCampaignById),
      mergeMap(params =>
        this.adminService.adminControllerGetCampaignById(params).pipe(
          map(campaign => programActions.getCampaignByIdSuccess({ campaign })),
          catchError(() => of(programActions.getCampaignByIdFailure())),
        ),
      ),
    ),
  );

  public getCampaignAnalytics$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(programActions.getCampaignAnalytics),
      mergeMap(params =>
        this.adminService.adminControllerGetCampaignAnalytics(params).pipe(
          map(campaignAnalytic => programActions.getCampaignAnalyticsSuccess({ campaignId: params.campaignId, campaignAnalytic })),
          catchError(() => of(programActions.getCampaignAnalyticsFailure())),
        ),
      ),
    ),
  );

  public checkCampaignSlug$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(programActions.checkCampaignSlug),
      mergeMap(params =>
        this.adminService.adminControllerCheckCampaignSlug(params).pipe(
          map(response => programActions.checkCampaignSlugSuccess({ response })),
          catchError(() => of(programActions.checkCampaignSlugFailure())),
        ),
      ),
    ),
  );

  public getCoupons$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(programActions.getCoupons),
      concatLatestFrom(() => this.store.select(fromProgram.selectCouponsFilters)),
      concatLatestFrom(() => this.store.select(fromProgram.selectCouponsSearchTerm)),
      mergeMap(([[params, status], searchTerm]) =>
        this.couponService.couponControllerGetCoupons({ ...params, status, searchTerm }).pipe(
          map(coupons => programActions.getCouponsSuccess({ limit: params.limit, coupons })),
          catchError(() => of(programActions.getCouponsFailure())),
        ),
      ),
    ),
  );

  public getCouponById$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(programActions.getCouponById),
      mergeMap(params =>
        this.couponService.couponControllerGetCouponById(params).pipe(
          map(coupon => programActions.getCouponByIdSuccess({ coupon })),
          catchError(() => of(programActions.getCouponByIdFailure())),
        ),
      ),
    ),
  );

  public createCoupon$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(programActions.createCoupon),
      mergeMap(params =>
        this.couponService.couponControllerStoreCoupon(params).pipe(
          mergeMap(coupon => of(programActions.createCouponSuccess({ coupon }))),
          catchError((error: HttpErrorResponse) => of(programActions.createCouponFailure({ formError: error.error?.response?.message }))),
        ),
      ),
    ),
  );

  public createCouponSuccess$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(programActions.createCouponSuccess),
        withLatestFrom(this.store.select(fromRouter.selectRouteParam(AppRouteParams.programSlug))),
        tap(([{ coupon }, programSlug]) => {
          const duration = 1000;

          this.toast.show({
            title: this.translate.instant("TOAST.save.title"),
            message: this.translate.instant("TOAST.save.message"),
            duration,
          });

          setTimeout(() => {
            this.router.navigate([programSlug, AppRoutes.content, AppRoutes.coupons, coupon.id]);
          }, duration);
        }),
      ),
    { dispatch: false },
  );

  public updateCoupon$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(programActions.updateCoupon),
      mergeMap(params =>
        this.couponService.couponControllerUpdateCoupon(params).pipe(
          map(coupon => programActions.updateCouponSuccess({ coupon })),
          catchError(error => of(programActions.updateCouponFailure({ formError: error?.response?.message }))),
        ),
      ),
    ),
  );

  public deleteCoupon$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(programActions.deleteCoupon),
      mergeMap(params =>
        this.couponService.couponControllerDeleteCoupon(params).pipe(
          map(() => programActions.deleteCouponSuccess({ couponId: params.couponId })),
          catchError(error => of(programActions.deleteCouponFailure({ formError: error?.response?.message }))),
        ),
      ),
    ),
  );

  public deleteCouponSuccess$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(programActions.deleteCouponSuccess),
        withLatestFrom(this.store.select(fromRouter.selectRouteParam(AppRouteParams.programSlug))),
        tap(([, programSlug]) => {
          this.router.navigate([programSlug, AppRoutes.content, AppRoutes.coupons]);
        }),
      ),
    { dispatch: false },
  );

  public uploadCodesFromCSV$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(programActions.generateCouponCodeBatch),
      mergeMap(({ couponId, programId, createCouponBatchDto, callback }) =>
        this.couponService.couponControllerCreateCouponBatch({ couponId, programId, createCouponBatchDto }).pipe(
          map(() => programActions.generateCouponCodeBatchSuccess({
            couponId,
            createdFrom: createCouponBatchDto.createdFrom,
            codesRequested: 0,
            callback,
          })),
          catchError(error => of(programActions.generateCouponCodeBatchFailure({ reason: error?.response?.message }))),
        ),
      ),
    ),
  );

  public removeUnclaimed$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(programActions.removeUnclaimed),
      mergeMap(({ programId, couponId, batchId }) =>
        this.couponService.couponControllerRemoveUnclaimedCodes({ programId, couponId, batchId }).pipe(
          map(coupon => removeUnclaimedSuccess({ coupon })),
          catchError(error => of(programActions.removeUnclaimedFailure({ reason: error?.response?.message }))),
        ),
      ),
    ),
  );

  public searchCoupons$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(programActions.searchCoupons),
      mergeMap(({ programId, searchTerm }) =>
        this.couponService.couponControllerSearchCoupons({ programId, searchTerm }).pipe(
          map(results => programActions.searchCouponsSuccess({ results })),
          catchError(error => of(programActions.searchCouponsFailure({ reason: error?.response?.message }))),
        ),
      ),
    ),
  );

  public getCouponStats$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(programActions.getCouponStats),
      mergeMap(({ programId, couponId }) =>
        this.couponService.couponControllerGetCouponCounts({ programId, couponId }).pipe(
          map(stats => programActions.getCouponStatsSuccess({ couponId, stats })),
          catchError(error => of(programActions.getCouponStatsFailure({ reason: error?.response?.message }))),
        ),
      ),
    ),
  );

  public claimAndDownloadCouponCodes$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(programActions.claimAndDownloadCouponCodes),
      mergeMap(({ programId, couponId, claimCouponCodes, callback }) =>
        this.couponService.couponControllerClaimAndDownloadCodes(
          {
            programId,
            couponId,
            claimCouponCodes,
          },
          "events",
          false,
          { httpHeaderAccept: "text/csv" as any },
        ).pipe(
          filter(response => response instanceof HttpResponse),
          map((response: any) => programActions.claimAndDownloadCouponCodesSuccess({ csv: response.body, callback })),
          catchError(error => of(programActions.claimAndDownloadCouponCodesFailure({ reason: error?.response?.message }))),
        ),
      ),
    ),
  );

  public claimAndDownloadCouponCodesSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(programActions.claimAndDownloadCouponCodesSuccess),
      tap(({ csv, callback }) => {
        const anchor = document.createElement("a");
        anchor.setAttribute("href", `data:attachment/csv;charset=utf-8${encodeURI(csv)}`);
        anchor.setAttribute("download", "coupon-codes.csv");
        anchor.click();

        callback();
      }),
    ), { dispatch: false },
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<RootState>,
    private readonly programService: ProgramService,
    private readonly adminService: AdminService,
    private readonly analyticsService: AnalyticsService,
    private readonly couponService: CouponService,
    private readonly router: Router,
    private readonly toast: ToastService,
    private readonly translate: TranslateService,
    private readonly integrationService: IntegrationService,
    private readonly dialog: MatDialog,
    private readonly shopifyAppBridgeService: ShopifyAppBridgeService,
    private readonly httpClient: HttpClient,
  ) {}

  private shopifyControllerTokenExchange(requestParameters: ShopifyControllerTokenExchangeRequestParams): Observable<string> {
    const tokenExchange = requestParameters.tokenExchange;
    if (tokenExchange === null || tokenExchange === undefined) {
      throw new Error("Required parameter tokenExchange was null or undefined when calling shopifyControllerTokenExchange.");
    }

    const programId = requestParameters.programId;
    if (programId === null || programId === undefined) {
      throw new Error("Required parameter programId was null or undefined when calling shopifyControllerTokenExchange.");
    }

    return this.httpClient.post(
      `${this.integrationService.configuration.basePath}/v1/programs/${encodeURIComponent(String(programId))}/integrations/shopify/token-exchange`,
      tokenExchange,
      { responseType: "text" },
    );
  }
}
